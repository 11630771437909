<template>
  <b-card>
    <b-row>
      <b-col offset="6" cols="6" class="mb-1">
        <b-form-group
          label="Filter"
          label-cols-sm="3"
          label-align-sm="right"
          label-size="sm"
          label-for="filterInput"
          class="mb-0 d-flex align-items-center"
        >
          <b-input-group size="sm">
            <b-form-input
              id="filterInput"
              v-model="filter"
              type="search"
              placeholder="Type to Search"
            />
            <b-input-group-append>
              <b-button :disabled="!filter" @click="filter = ''">
                Clear
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-modal
        id="modal-danger"
        ok-only
        ok-variant="danger"
        ok-title="Yes"
        modal-class="modal-danger"
        centered
        title="Delete"
        @ok="deleteNotif"
      >
        <b-card-text>
          Are you sure you want to delete this notification?
        </b-card-text>
      </b-modal>
      <b-modal
        id="modal-warning"
        ok-only
        ok-variant="warning"
        ok-title="Accept"
        modal-class="modal-warning"
        centered
        title="Send"
        @ok="sendAllNotifs"
      >
        <b-card-text>
          Are you sure you want to send these notifications?
        </b-card-text>
      </b-modal>
      <b-modal
        id="modal-warning-mark"
        ok-only
        ok-variant="warning"
        ok-title="Accept"
        modal-class="modal-warning"
        centered
        title="Mark as sent"
        @ok="markAsDone"
      >
        <b-card-text>
          Are you sure you want to mark this notification as sent?
        </b-card-text>
      </b-modal>
      <b-modal
        id="modal-warning-program"
        ok-only
        ok-variant="warning"
        ok-title="Accept"
        modal-class="modal-warning"
        centered
        title="Program"
        @ok="programAllNotifs"
      >
        <b-card-text>
          Are you sure you want to program these notifications?
        </b-card-text>
      </b-modal>

      <b-modal
        id="modal-default-send"
        ok-variant="outline-success"
        scrollable
        :title="accTitle"
        size="xl"
        :hide-footer="true"
        ok-only
        ok-title="Close"
      >
        <div class="day-group">
          <div class="demo-inline-spacing days-div">
            <div>Filter by days:</div>
            <br />
            <span
              class="day-span"
              v-for="day in weekDays"
              :key="day.value"
              @click="showDayNotifs(day.value)"
            >
              <b-avatar class="day-avatar" variant="primary">{{
                day.text
              }}</b-avatar>
            </span>
          </div>
        </div>
        <b-table :items="accNotifications" :fields="tableFields">
          <b-col md="2" sm="4" class="my-1">
            <b-form-group class="mb-0">
              <label class="d-inline-block text-sm-left mr-50">Per page</label>
              <b-form-select
                id="perPageSelect"
                v-model="perPage"
                size="sm"
                :options="pageOptions"
                class="w-25"
              />
            </b-form-group>
          </b-col>

          <b-col md="4" class="my-1">
            <b-form-group
              label="Filter"
              label-cols-sm="3"
              label-align-sm="right"
              label-size="sm"
              label-for="filterInput"
              class="mb-0"
            >
              <b-input-group size="sm">
                <b-form-input
                  id="filterInput"
                  v-model="filter"
                  type="search"
                  placeholder="Type to Search"
                />
                <b-input-group-append>
                  <b-button :disabled="!filter" @click="filter = ''">
                    Clear
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-col>
          <template #cell(title)="data">
            <!-- <b-badge variant="info" class="badge-glow app-badge"> -->
            {{ data.item.title }}
            <!-- </b-badge> -->
          </template>

          <template #cell(notification_title)="data">
            <b-form-input
              v-model="data.item.notification_title"
              class="form-control"
            ></b-form-input>
          </template>
          <template #cell(notification_content)="data">
            <b-form-textarea
              type="text"
              v-model="data.item.notification_content"
            ></b-form-textarea>
          </template>
          <template #cell(notification_time)="data">
            <div class="timepicker-section">
              <b-form-timepicker
                v-model="data.item.notif_time"
                class="timepicker-placeholder"
                placeholder="Time"
                locale="en"
              ></b-form-timepicker>
            </div>
          </template>
          <template #cell(notification_link)="data">
            <b-form-input
              v-model="data.item.notification_link"
              class="form-control"
            ></b-form-input>
          </template>
          <template #cell(status)="data">
            <div class="feathers">
              <b-spinner
                v-if="notifLoading.includes(data.item.id)"
                variant="success"
                label="Text Centered"
                v-show="if_today"
              />
            </div>
            <div class="feathers">
              <feather-icon
                v-show="if_today"
                v-if="accountNotifsSendState[data.item.id] > 0"
                icon="CheckIcon"
                class="sentIcon"
              />
              <feather-icon
              v-show="if_today"
                v-if="accountNotifsSendState[data.item.id] === 0"
                icon="AlertTriangleIcon"
                class="notSentIcon"
              />
            </div>

            <div class="resend">
              <b-button
              v-show="if_today"
                size="sm"
                v-if="accountNotifsSendState[data.item.id] === 0"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                @click="resendNotif(data.item)"
              >
                <span>Resend</span>
              </b-button>
            </div>
            <div class="delayTime" v-show="if_today">
              <b-badge
                v-if="
                  accountNotifsSendState[data.item.id] > 0 &&
                  data.item.notification_link &&
                  data.item.notification_link != ''
                "
                variant="info"
                class="badge-glow"
              >
                Delayed by 4 hours
              </b-badge>
            </div>
            <!-- </span> -->
            <!-- </b-button> -->
          </template>
          <template #cell(markDone)="data">
            <b-form-checkbox
              v-if="accountNotifsSendState[data.item.id] === 0"
              class="custom-control-success"
              @change="popMarkAsDone(data.item)"
            >
            </b-form-checkbox>
          </template>
        </b-table>
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          v-show="if_today"
          @click="confirmSend()"
          :disabled="sendAllDisabled"
        >
          <feather-icon icon="SendIcon" class="ml-25" />
          &nbsp;
          <span>Send all notifications</span>
        </b-button>
      </b-modal>
      <!-- The program modal -->
      <b-modal
        id="modal-default-program"
        ok-variant="outline-success"
        scrollable
        :title="accTitle"
        size="xl"
        :hide-footer="true"
        ok-only
        ok-title="Close"
      >
        <b-table :items="accNotifications" :fields="programTableFields">
          <template #cell(title)="data">
            {{ data.item.title }}
          </template>
          <template #cell(notification_content)="data">
            <b-form-textarea
              type="text"
              v-model="data.item.notification_content"
            ></b-form-textarea>
          </template>
          <template #cell(notification_title)="data">
            <b-form-input
              v-model="data.item.notification_title"
              class="form-control"
            ></b-form-input>
          </template>

          <template #cell(notification_link)="data">
            <b-form-input
              v-model="data.item.notification_link"
              class="form-control"
            ></b-form-input>
          </template>
          <template #cell(status)="data">
            <b-spinner
              v-if="notifLoading.includes(data.item.id)"
              variant="success"
              label="Text Centered"
            />

            <span v-ripple.400="'rgba(255, 255, 255, 0.15)'">
              <feather-icon
                v-if="accountNotifsProgramState[data.item.id] > 0"
                icon="CheckIcon"
                class="sentIcon"
              />
              <feather-icon
                v-if="accountNotifsProgramState[data.item.id] == 0"
                icon="AlertTriangleIcon"
                class="notSentIcon"
              />
            </span>
            <div class="reprogram">
              <b-button
                size="sm"
                v-if="accountNotifsProgramState[data.item.id] == 0"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                @click="reprogramNotif(data.item)"
              >
                <!-- <feather-icon icon="ClockIcon" class="mr-1" /> -->
                <span>Reprogram</span>
              </b-button>
            </div>
          </template>
        </b-table>
        <b-row>
          <b-col>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              @click="confirmProgram()"
              :disabled="programAllDisabled"
            >
              <feather-icon icon="ClockIcon" class="mr-25" />
              <span>Program all notifications</span>
            </b-button>
          </b-col>
          <b-col cols="4" class="mb-2">
            <v-select
              class="day"
              v-model="weekendSelected"
              label="title"
              :options="weekend"
              @input="weekendDaySelected(weekendSelected)"
            />
          </b-col>
          <b-col cols="4">
            <div class="timeDiv">
              <b-input-group class="mb-1">
                <b-form-input
                  id="example-input"
                  v-model="programTime"
                  type="text"
                  placeholder="HH:mm:ss"
                />
                <b-input-group-append>
                  <b-form-timepicker
                    v-model="programTime"
                    button-only
                    right
                    show-seconds
                    locale="en"
                    aria-controls="example-input"
                  />
                </b-input-group-append>
              </b-input-group>
            </div>
          </b-col>
        </b-row>
      </b-modal>
      <b-col cols="12">
        <b-table
          striped
          hover
          responsive
          :per-page="perPage"
          :current-page="currentPage"
          :items="items"
          :fields="fields"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection"
          :filter="filter"
          :filter-included-fields="filterOn"
          @filtered="onFiltered"
        >
          <template #cell(sendNotif)="data">
            <b-button
              v-ripple.400="'rgba(255, 159, 67, 0.15)'"
              variant="flat-info"
              class="btn-icon rounded-circle"
              v-b-tooltip.hover
              title="Send"
              @click="popSendNotif(data.item)"
              :disabled="clicked.includes(data.item.id)"
            >
              <feather-icon icon="SendIcon" size="20" />
            </b-button>
          </template>
          <template #cell(programNotif)="data">
            <b-button
              v-ripple.400="'rgba(255, 159, 67, 0.15)'"
              variant="flat-warning"
              class="btn-icon rounded-circle"
              v-b-tooltip.hover
              title="Program"
              @click="popProgramNotif(data.item)"
            >
              <feather-icon icon="ClockIcon" size="20" />
            </b-button>
          </template>
          <template>
            <b-button
              v-ripple.400="'rgba(255, 159, 67, 0.15)'"
              variant="flat-success"
              class="btn-icon rounded-circle"
              v-b-tooltip.hover
              title="Program"
            >
              <feather-icon icon="CheckCircleIcon" size="20" />
            </b-button>
          </template>
        </b-table>
      </b-col>
      <b-col cols="6">
        <b-form-group class="mb-0">
          <label class="d-inline-block text-sm-left mr-50">Per page</label>
          <b-form-select
            id="perPageSelect"
            v-model="perPage"
            size="sm"
            :options="pageOptions"
            class="w-25"
          />
        </b-form-group>
      </b-col>

      <b-col cols="6">
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          align="center"
          size="sm"
          class="my-0"
        />
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BTable,
  BAvatar,
  BImg,
  BBadge,
  BCard,
  VBTooltip,
  BButtonGroup,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BCardText,
  BButton,
  BModal,
  VBModal,
  BForm,
  BFormTextarea,
  BFormTimepicker,
  BSpinner,
  BFormCheckbox,
} from "bootstrap-vue";

import Ripple from "vue-ripple-directive";
import { heightTransition } from "@core/mixins/ui/transition";
import axios from "@axios";
import axioss from "axios";
import store from "@/store";
import router from "@/router";

import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { removeLocalStorageItems } from "@/helper/helpers-funcs.js";
import vSelect from "vue-select";
import moment from "moment";
import { BRow, BCol, BCalendar, BTime } from "bootstrap-vue";
import Prism from "vue-prism-component";
import "prismjs";
import "prismjs/themes/prism-tomorrow.css";

export default {
  components: {
    BFormCheckbox,
    BFormTimepicker,
    vSelect,
    BTable,
    BAvatar,
    VBTooltip,
    BCard,
    BImg,
    BButtonGroup,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    ValidationProvider,
    ValidationObserver,
    BModal,
    VBModal,
    BCardText,
    Prism,
    BCalendar,
    BTime,
    BForm,
    BFormTextarea,
    BSpinner,
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  mixins: [heightTransition],
  data() {
    return {
      accountNotifsSendState: {},
      accountNotifsProgramState: {},
      tableFields: [
        { key: "title", label: "appName" },
        { key: "notification_title", label: "Notification title" },
        { key: "notification_content", label: "Notification content" },
        { key: "notification_link", label: "Notification link" },
        { key: "status", label: "Notification status" },
        { key: "markDone", label: "Mark as sent" },
      ],
      programTableFields: [
        { key: "title", label: "appName" },
        { key: "notification_content", label: "Notification content" },
        { key: "notification_title", label: "Notification title" },
        { key: "notification_link", label: "Notification link" },
        { key: "status", label: "Notification status" },
      ],
      weekDays: [
        { text: "D1", value: 1 },
        { text: "D2", value: 2 },
        { text: "D3", value: 3 },
        { text: "D4", value: 4 },
        { text: "D5", value: 5 },
      ],
      programTime: "10:00:00",
      value: null,

      timeContext: "",
      programOnTimeContext: "",
      context: "",

      selectedAccount: "",
      accountSelected: "",
      accounts: [],

      mon: false,
      account_name: null,
      account_link: null,
      perPage: 10,
      pageOptions: [10, 25, 100],
      totalRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      infoModal: {
        id: "info-modal",
        title: "",
        content: "",
      },
      notifIdToDelete: null,
      sendData: [],
      programData: [],
      fields: [
        {
          key: "account_name",
          label: "Account name",
        },
        "sendNotif",
        "programNotif",
      ],

      items: [],
      clicked: [],
      thisAccountId: null,
      nextTodoId: 2,
      accNotifications: null,
      programAccNotifications: null,
      accTitle: null,

      programThisAccount: null,
      weekendSelected: "Saturday",
      weekend: ["Saturday", "Sunday"],
      notifLoading: [],
      notificationSent: [],
      notificationNotSent: [],
      notificationProgrammed: [],
      notificationNotProgrammed: [],
      resendButtonArray: [],
      reprogramButtonArray: [],
      currentAcc: null,
      thisNotifHistoryId: null,
      thisProgramNotifHistoryId: null,
      sendAllDisabled: null,
      programAllDisabled: null,
      if_today : true,
    backup : null,
    };
  },
  computed: {
    sortOptions() {
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },
  },
  mounted() {
    this.totalRows = this.items.length;
    this.initTrHeight();
  },
  created() {
    this.showAllAccounts();
    this.clicked = [];
    window.addEventListener("resize", this.initTrHeight);
  },
  destroyed() {
    window.removeEventListener("resize", this.initTrHeight);
  },
  methods: {
    programAccAllCheck(acc_id) {
      var selectedWeekendDay = this.weekendSelected;
      var date;
      var selectedWeekendDayNum;
      if (selectedWeekendDay === "Saturday") {
        selectedWeekendDayNum = 6;
      } else if (selectedWeekendDay === "Sunday") {
        selectedWeekendDayNum = 0;
      }
      date = moment(this.nextDay(selectedWeekendDayNum)).format("YYYY-MM-DD");
      // var date='2023-01-14'
      axios
        .post("/checkIfAccProgrammed", {
          account_id: acc_id,
          weekend_date: date,
        })
        .then(({ data }) => {
          if (data.message == 1) {
            this.programAllDisabled = true;
          } else if (data.message == 0) {
            this.programAllDisabled = false;
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    sendAccAllCheck(acc_id) {
      axios
        .post("/checkIfAccSent", {
          account_id: acc_id,
        })
        .then(({ data }) => {
          if (data.message == 1) {
            this.sendAllDisabled = true;
          } else if (data.message == 0) {
            this.sendAllDisabled = false;
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    popMarkAsDone(data) {
      this.itemToBeMarked = data;
      this.$root.$emit("bv::show::modal", "modal-warning-mark");
    },
    markAsDone() {
      var data = this.itemToBeMarked;
      axios
        .post("/addToHistory", {
          notif_id: data.id,
          recipients: 0,
          successfully_sent: true,
          error: "null",
        })
        .then((response) => {
          if (this.resendButtonArray.includes(data.id)) {
            this.resendButtonArray.splice(
              this.notifLoading.indexOf(data.id),
              1
            );
          }
          this.setUpSendStateObj();
          this.setUpProgramStateObj();
        })
        .catch((error) => {
          this.showToast(
            "danger",
            "AlertTriangleIcon",
            "Error",
            "History update problem: " + error
          );
        });
    },
    /** Reprograms a notification in case there was a problem **/
    reprogramNotif(data) {
      let dayOfTheWeekend = 6;
      let nextDateOfChosenDay;
      if (this.weekendSelected === "Saturday") {
        dayOfTheWeekend = 6;
      } else if (this.weekendSelected === "Sunday") {
        dayOfTheWeekend = 0;
      }
      nextDateOfChosenDay = moment(this.nextDay(dayOfTheWeekend)).format(
        "YYYY-MM-DD"
      );
      this.accountNotifsProgramState[data.id] = -1;
      this.notifLoading.push(data.id);
      this.programOneNotif(data, nextDateOfChosenDay, this.programTime);
    },

    /** Resends a notification in case there was a problem **/
    resendNotif(data) {
      this.accountNotifsSendState[data.id] = -1;
      this.notifLoading.push(data.id);
      this.sendNotification(data);
    },
    /** Returns next date of a specific day**/
    nextDay(x) {
      var now = new Date();
      now.setDate(now.getDate() + ((x + (7 - now.getDay())) % 7));
      return now;
    },
    /** checks if a weekend notif was programmed **/
    checkIfProgrammed(id) {
      var nextSaturday = moment(this.nextDay(6)).format("YYYY-MM-DD");
      var nextSunday = moment(this.nextDay(0)).format("YYYY-MM-DD");
      var checkWeekendDay;
      if (this.weekendSelected == "Saturday") {
        checkWeekendDay = nextSaturday;
      } else if (this.weekendSelected == "Sunday") {
        checkWeekendDay = nextSunday;
      }
      axios
        .post("/checkIfProgrammed", {
          notif_id: id,
          weekend_date: checkWeekendDay,
        })
        .then((response) => {
          if (response.data.message == 1) {
            //programmed
            this.notificationProgrammed.push(id);
            if (this.notificationNotProgrammed.includes(id)) {
              this.notificationNotProgrammed.splice(
                this.notificationNotProgrammed.indexOf(id),
                1
              );
            }
          } else if (response.data.message == 0) {
            //failed
            this.notificationNotProgrammed.push(id);
            if (this.notificationProgrammed.includes(id)) {
              this.notificationProgrammed.splice(
                this.notificationProgrammed.indexOf(id),
                1
              );
            }
            this.reprogramButtonArray.push(id);
          } else {
            //not programmed yet
            this.notificationNotProgrammed.push(id);
            if (this.notificationProgrammed.includes(id)) {
              this.notificationProgrammed.splice(
                this.notificationProgrammed.indexOf(id),
                1
              );
            }
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    /** checks if a notif was sent **/
    sendStatus(id) {
      axios
        .post("/checkSendingStatus", {
          notif_id: id,
        })
        .then((response) => {
          if (response.data.message == 1) {
            // sent
            this.accNotifications[0]["sendStatus"] == true;
            this.notificationSent.push(id);
            if (this.notificationNotSent.includes(id)) {
              this.notificationNotSent.splice(
                this.notificationNotSent.indexOf(id),
                1
              );
            }
          } else if (response.data.message == 0) {
            this.accNotifications[0]["sendStatus"] == false;
            // failed
            this.notificationNotSent.push(id);
            if (this.notificationSent.includes(id)) {
              this.notificationSent.splice(
                this.notificationSent.indexOf(id),
                1
              );
            }
            this.resendButtonArray.push(id);
          } else {
            // not sent yet
            this.notificationNotSent.push(id);
            if (this.notificationSent.includes(id)) {
              this.notificationSent.splice(
                this.notificationSent.indexOf(id),
                1
              );
            }
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    /** pops the program modal and calls the functions that'll dsplay notifs **/
    popProgramNotif(item) {
      this.currentAcc = item;
      this.thisAccountId = item.id;
      this.accTitle = "Program notifications of " + item.account_name;
      this.$root.$emit("bv::show::modal", "modal-default-program");
      this.weekendDaySelected(this.weekendSelected);
    },
    weekendDaySelected(day) {
      
      if (day == "Saturday") {
        this.popWeekendAccountNotifs(6, this.thisAccountId);

      } else {
        this.popWeekendAccountNotifs(7, this.thisAccountId);
      }
    },
    /** fetches and displays account weekend notifs + checks if programmed**/
    popWeekendAccountNotifs(day, accountId) {
      this.accountNotifsProgramState = {};
      this.programAccAllCheck(accountId);
      this.notificationProgrammed = [];
      this.notificationNotProgrammed = [];
      this.reprogramButtonArray = [];
      axios
        .post("/getNotifsByAccount", {
          account_id: accountId,
        })
        .then(({ data }) => {
          this.accNotifications = data[0];
          this.accNotifications = this.accNotifications.filter(x=> x.notif_day === day)
          
          this.accNotifications.forEach((weekendNotif) => {
            // this.checkIfProgrammed(weekendNotif.id);
            this.setUpProgramStateObj();
          });
        })
        .catch(console.error);
    },
    confirmProgram() {
      this.$root.$emit("bv::show::modal", "modal-warning-program");
    },
    confirmSend() {
      this.$root.$emit("bv::show::modal", "modal-warning");
    },

    async programOneNotif(data, date, exactTime) {
      var sendTime = exactTime;
      var historyCreatedAt = date + " " + sendTime;
      var sendAfter = date + " " + sendTime + "GMT+0100";
      let info = {};
      info = {
        appId: data.credentials_appId,
        included_segments: ["Subscribed Users"],
        data: { foo: "bar" },
        headings: { en: data.notification_title },
        contents: { en: data.notification_content },
        send_after: sendAfter,
        delayed_option: "last-active",
      };
      if (data.notification_link && data.notification_link != "") {
        info.url = data.notification_link;
      }
      const config = {
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          Authorization: `Basic ${data.credentials_api_key}`,
        },
      };
      axios
        .post("/addToHistory", {
          notif_id: data.notif_id,
          recipients: 0,
          created_at: historyCreatedAt,
          successfully_sent: false,
          error: "null",
        })
        .then(({ data }) => {
          this.thisProgramNotifHistoryId = data[0].id;

          axioss
            .post("https://onesignal.com/api/v1/notifications", info, config)
            .then((res) => {
              if (res.status == 200) {
                axios
                  .post("/updateHistory", {
                    id: this.thisProgramNotifHistoryId,
                    recipients: res.data.recipients,
                    created_at: historyCreatedAt,
                    successfully_sent: true,
                    error: "null",
                  })
                  .then((response) => {

                    this.setUpProgramStateObj();
                    this.notifLoading.splice(
                      this.notifLoading.indexOf(data.id),
                      1
                    );
                  })
                  .catch((error) => {
                    console.error(error);
                  });
              }
            })
            .catch((error) => {
              this.showToast(
                "danger",
                "AlertTriangleIcon",
                "Error",
                "Bad request: make sure you've configured the app credentials"
              );

              axios
                .post("/updateHistory", {
                  id: this.thisProgramNotifHistoryId,
                  recipients: 0,
                  created_at: historyCreatedAt,
                  successfully_sent: false,
                  error: "program error",
                })
                .then((response) => {
                  this.setUpProgramStateObj();
                  this.notifLoading.splice(
                    this.notifLoading.indexOf(data.id),
                    1
                  );
                })
                .catch((error) => {
                  this.showToast(
                    "danger",
                    "AlertTriangleIcon",
                    "Error",
                    "history problem: " + error
                  );
                });
            });
        })
        .catch((error) => {
          console.error(error);
        });
    },
    async programForSendOneNotif(data, date, exactTime) {
      var sendTime = exactTime;
      var historyCreatedAt = date + " " + sendTime;
      var sendAfter = date + " " + sendTime + "GMT+0100";
      let info = {};
      info = {
        appId: data.credentials_appId,
        included_segments: ["Subscribed Users"],
        data: { foo: "bar" },
        headings: { en: data.notification_title },
        contents: { en: data.notification_content },
        send_after: sendAfter,
        delayed_option: "last-active",
      };
      if (data.notification_link && data.notification_link != "") {
        info.url = data.notification_link;
      }
      const config = {
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          Authorization: `Basic ${data.credentials_api_key}`,
        },
      };
      axios
        .post("/addToHistory", {
          notif_id: data.notif_id,
          recipients: 0,
          created_at: historyCreatedAt,
          successfully_sent: false,
          error: "null",
        })
        .then(({ data }) => {
          this.thisProgramNotifHistoryId = data[0].id;

          axioss
            .post("https://onesignal.com/api/v1/notifications", info, config)
            .then((res) => {
              if (res.status == 200) {
                axios
                  .post("/updateHistory", {
                    id: this.thisProgramNotifHistoryId,
                    recipients: res.data.recipients,
                    created_at: historyCreatedAt,
                    successfully_sent: true,
                    error: "null",
                    // send_date: now,
                  })
                  .then((response) => {
                    this.sendStatus(data.notif_id);
                    this.notifLoading.splice(
                      this.notifLoading.indexOf(data.notif_id),
                      1
                    );
                  })
                  .catch(console.error);
              }
            })
            .catch((error) => {
              this.showToast(
                "danger",
                "AlertTriangleIcon",
                "Error",
                "Bad request: make sure you've configured the app credentials"
              );

              axios
                .post("/updateHistory", {
                  id: this.thisProgramNotifHistoryId,
                  recipients: 0,
                  created_at: historyCreatedAt,
                  successfully_sent: false,
                  error: "program error",
                })
                .then((response) => {
                  // this.sendStatus(data.id);
                })
                .catch((error) => {
                  this.showToast(
                    "danger",
                    "AlertTriangleIcon",
                    "Error",
                    "history problem: " + error
                  );
                });
              this.checkIfProgrammed(data.notif_id);
              this.notifLoading.splice(this.notifLoading.indexOf(data.notif_id), 1);
            })
            .then(() => {
              this.setUpSendStateObj();
            });
        })
        .catch((error) => {
          console.error(error);
        });
    },
    async programAllNotifs() {
      let dataToBeProgrammed = this.accNotifications;
      let dayOfTheWeekend = 6;
      let nextDateOfChosenDay;
      if (this.weekendSelected === "Saturday") {
        dayOfTheWeekend = 6;
      } else if (this.weekendSelected === "Sunday") {
        dayOfTheWeekend = 0;
      }

      nextDateOfChosenDay = moment(this.nextDay(dayOfTheWeekend)).format(
        "YYYY-MM-DD"
      );

      var linkTime = moment(this.programTime, "HH:mm:ss")
        .add(4, "hours")
        .format("HH:mm:ss");
      var delayTime = 0;
      dataToBeProgrammed.forEach((notif) => {
        if (notif.notification_link && notif.notification_link != "") {
          this.notifLoading.push(notif.id);
          setTimeout(() => {
            this.programOneNotif(notif, nextDateOfChosenDay, linkTime);
          }, delayTime);
        } else if (!notif.notification_link || notif.notification_link == "") {
          this.notifLoading.push(notif.id);
          setTimeout(() => {
            this.programOneNotif(notif, nextDateOfChosenDay, this.programTime);
          }, delayTime);
        }
        delayTime += 10000;
      });
      this.programAllDisabled = true;
      // this.weekendDaySelected(this.weekendSelected);
    },
    sendAllNotifs() {
      var now = new Date();
      var nowDate = moment(now).format("YYYY-MM-DD");
      var nowTime = moment(now).format("HH:mm:ss");
      var linkTime = moment(new Date(), "HH:mm:ss")
        .add(2, "hours")
        .format("HH:mm:ss");
      var delayTime = 0;
      this.accNotifications.forEach((element) => {
        if (element.notification_link && element.notification_link != "") {
          this.notifLoading.push(element.id);
          setTimeout(() => {
            this.programForSendOneNotif(element, nowDate, linkTime);
          }, delayTime);
        } else if (
          !element.notification_link ||
          element.notification_link == ""
        ) {
          this.notifLoading.push(element.id);
          setTimeout(() => {
            this.sendNotification(element);
          }, delayTime);
        }
        delayTime += 10000;
      });
      this.popAfterSend();
    },

    removeItem(index) {
      this.items.splice(index, 1);
      this.trTrimHeight(this.$refs.row[0].offsetHeight);
    },
    initTrHeight() {
      this.trSetHeight(null);
      this.$nextTick(() => {});
    },
    onTimeContext(ctx) {
      this.timeContext = ctx;
    },
    onContext(ctx) {
      this.context = ctx;
    },

    programPop(data) {
      this.$root.$emit("bv::show::modal", "modal-default");
      this.programData = data;
    },
    popSendNotif(item) {
      var currentDate = new Date();
      var dateOfSend = moment(currentDate).format("DD/MM/YYYY");

      this.thisAccountId = item.id;
      this.accTitle =
        "Send notifications of " + item.account_name + ": " + dateOfSend;
      this.$root.$emit("bv::show::modal", "modal-default-send");
      this.popAccountNotifs();
    },
    setDeleteId(id) {
      this.notifIdToDelete = id;
      this.$root.$emit("bv::show::modal", "modal-danger");
    },

    async showAllAccounts() {
      this.$store
        .dispatch("appsManager/getAllAccounts")
        .then(({ data }) => {
          data.accounts.forEach((account) => {
            axios
              .post("/get-onesignal-accounts", {
                account_id: account.id,
              })
              .then((response) => {
                if (response.data.onesignal > 0) {
                  this.accounts.push({
                    title: account.account_name,
                    value: account.id,
                  });
                  this.items.push(account);
                }
              })
              .catch(console.error);
          });
        })
        .catch(console.error);
    },
    async sendNotification(data) {
      // 
      // 
      // 
      var info;
      var baseInfo = {
        appId: data.credentials_appId,
        included_segments: ["Subscribed Users"],
        data: { foo: "bar" },
        headings: { en: data.notification_title },
        contents: { en: data.notification_content },
        // delayed_option: "last-active",
      };
      var today = new Date().getDay();
      if (today == data.notif_day) {
        
        if (!data.notification_link || data.notification_link == "") {
          
          info = baseInfo;
        } else if (data.notification_link && data.notification_link != "") {
          
          info = {
            ...baseInfo,
            ["url"]: data.notification_link,
          };
        }
        const config = {
          headers: {
            "Content-Type": "application/json; charset=utf-8",
            Authorization: `Basic ${data.credentials_api_key}`,
          },
        };
        var successfullySent;
        // 
        axios
          .post("/addToHistory", {
            notif_id: data.notif_id,
            recipients: 0,
            successfully_sent: false,
            error: "null",
          })
          .then(({ data }) => {
            
            this.thisNotifHistoryId = data[0].id;
            axioss
              .post("https://onesignal.com/api/v1/notifications", info, config)
              .then((res) => {
                
                if (res.status == 200) {
                  successfullySent = true;
                }
                axios
                  .post("/updateHistory", {
                    id: this.thisNotifHistoryId,
                    recipients: res.data.recipients,
                    successfully_sent: successfullySent,
                    error: "null",
                  })
                  .then((response) => {
                    this.setUpSendStateObj();
                    this.notifLoading.splice(
                      this.notifLoading.indexOf(data.notif_id),
                      1
                    );
                  })
                  .catch((error) => {
                    this.showToast(
                      "danger",
                      "AlertTriangleIcon",
                      "Error",
                      "History update problem: " + error
                    );
                  });
                return res;
              })
              .catch((error) => {
                
                this.showToast(
                  "danger",
                  "AlertTriangleIcon",
                  "Error",
                  "Bad request: make sure you've configured the app credentials"
                );
                axios
                  .post("/updateHistory", {
                    id: this.thisNotifHistoryId,
                    recipients: 0,
                    successfully_sent: false,
                    error: "error for now",
                    // send_date: now,
                  })
                  .then((response) => {
                    this.sendStatus(data.id);
                  })
                  .catch((error) => {
                    this.showToast(
                      "danger",
                      "AlertTriangleIcon",
                      "Error",
                      "history problem: " + error
                    );
                  });
                // this.sendStatus(data.id);
                this.setUpSendStateObj();
                this.notifLoading.splice(this.notifLoading.indexOf(data.id), 1);
                // this.resendButtonArray.push(data.id);
                return error;
              });
          })
          .catch((error) => {
            this.showToast(
              "danger",
              "AlertTriangleIcon",
              "Error",
              "History problem:" + error
            );
          });
        return;
      } else {
        this.showToast(
          "danger",
          "AlertTriangleIcon",
          "Error",
          "You can't send this notification today!"
        );
      }
    },
    deleteNotif() {
      axios
        .post("/destroyNotification", {
          id: this.notifIdToDelete,
        })
        .then(({ data }) => {
          if (data.status == "Unauthorized") {
            removeLocalStorageItems();
            router.push({ name: "auth-login" });
          } else if (data.status == "success") {
            this.showToast("success", "SaveIcon", "Done", data.message);
            this.showAccountNotifs();
          } else if (data.status == "failed") {
            this.showToast(
              "danger",
              "AlertTriangleIcon",
              "Error",
              "Something went wrong!"
            );
          } else if (data.status == "warning") {
            this.showToast(
              "warning",
              "AlertOctagonIcon",
              "Alert",
              data.message
            );
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    editNotif: (id) => {
      store
        // .dispatch("appsManager/setDomainId", id)
        .dispatch("appsManager/setNotifId", id)
        .then((response) => {
          router.push({ name: "notifications-edit" });
        })
        .catch((error) => {
          console.error(error);
        });
    },
    showToast(variant, icon, title, text) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          text,
          variant,
        },
      });
    },
    popAfterSend() {
      this.sendAccAllCheck(this.thisAccountId);
      this.resendButtonArray = [];
      this.notificationSent = [];
      this.notificationNotSent = [];
      var currentDate = new Date();

      axios
        .post("/getNotifsByAccount", {
          account_id: this.thisAccountId,
          notif_day: currentDate.getDay(),
        })
        .then(({ data }) => {
          this.accNotifications = data[0];

          this.showDayNotifs(currentDate.getDay())
          // this.setUpSendStateObj()
        }).then(_=>{
          // this.showDayNotifs(currentDate.getDate())
        })
        .catch((error) => {
          console.error(error);
        });
    },
    setListNotifStateForSend(id, message) {
      /*updates notif status in the setUpSendStateObj object*/
      this.accountNotifsSendState = {
        ...this.accountNotifsSendState,
        [id]: message,
      };
    },

    async setUpSendStateObj() {
      // this.accountNotifsSendState={};
      this.accNotifications.forEach((notif) => {
        // 
        axios
          .post("/checkSendingStatus", {
            notif_id: notif.notif_id,
          })
          .then(({ data }) => {
            
            this.setListNotifStateForSend(notif.id, data.message);
          })
          .catch(console.error);
      });
    },
    setListNotifStateForProgram(id, message) {
      // updates notif status in the accountNotifsProgramState object
      this.accountNotifsProgramState = {
        ...this.accountNotifsProgramState,
        [id]: message,
      };
    },
    async setUpProgramStateObj() {
      var nextSaturday = moment(this.nextDay(6)).format("YYYY-MM-DD");
      var nextSunday = moment(this.nextDay(0)).format("YYYY-MM-DD");
      var checkWeekendDay;
      if (this.weekendSelected == "Saturday") {
        checkWeekendDay = nextSaturday;
      } else if (this.weekendSelected == "Sunday") {
        checkWeekendDay = nextSunday;
      }
      // this.accountNotifsProgramState = {};
      this.accNotifications.forEach((notif) => {
        axios
          .post("/checkIfProgrammed", {
            notif_id: notif.notif_id,
            weekend_date: checkWeekendDay,
          })
          .then(({ data }) => {
            this.setListNotifStateForProgram(notif.id, data.message);
          })
          .catch(console.error);
      });
    },
    popAccountNotifs() {
      this.accountNotifsSendState = {};
      this.sendAccAllCheck(this.thisAccountId);
      this.resendButtonArray = [];
      this.notificationSent = [];
      this.notificationNotSent = [];
      var currentDate = new Date();
      axios
        .post("/getNotifsByAccount", {
          account_id: this.thisAccountId,
        })
        .then(({ data }) => {
          this.accNotifications = data[0];
          this.backup = data[0];
        }).then(()=>{
          this.showDayNotifs(currentDate.getDay())
          this.setUpSendStateObj();
        })
        .catch(console.error);
    },
    showDayNotifs(day) {
      const currentDate = new Date();
      if(currentDate.getDay() === day ){
        this.if_today = true;
      }
      if(currentDate.getDay() !== day){
        this.if_today = false;
      }
      
      
      this.dayNotif = day;
      var element = document.getElementsByClassName("day-avatar")[day - 1];
      this.daysAreFiltered = true;
      var dayAvatars = document.getElementsByClassName("day-avatar");
      dayAvatars.forEach((dayAvatar) => {
        dayAvatar.classList.remove("day-active");
      });
      element.parentElement.className += " day-active";
      var notifications;
      notifications = this.backup.filter((e) => e.notif_day === day);
      this.accNotifications = notifications;
      // 
    },
    showAllNotifs() {
      this.$store
        .dispatch("appsManager/getAllNotifs")
        .then(({ data }) => {
          this.items = data[0];
          this.totalRows = this.items.length;
        })
        .catch(console.error);
    },
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`;
      this.infoModal.content = JSON.stringify(item, null, 2);
      this.$root.$emit("bv::show::modal", this.infoModal.id, button);
    },
    resetInfoModal() {
      this.infoModal.title = "";
      this.infoModal.content = "";
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/pages/dashboard-ecommerce.scss";
@import "@core/scss/vue/libs/vue-select.scss";

.sentIcon {
  height: 33px;
  width: 33px;
  stroke: $success;
}
.notSentIcon {
  height: 33px;
  width: 33px;
  stroke: $warning;
}
.status {
  display: flex;
  flex-direction: column !important;
  position: relative;
  text-align: center;
}
.feathers {
  position: relative;
  left: 50%;
  transform: translate(-50%, 0);
  display: inline-block;
}
.resend,
.reprogram {
  width: calc(50%) !important;
  position: relative;
  left: 50%;
  transform: translate(-50%, 0);
  margin-top: 2px;
}
.delayTime {
  width: calc(50%) !important;
}
.daysFilter {
  font-size: 0.9em;
}
.day-avatar:hover {
  cursor: pointer;
  transform: scale(1.1);
}
.day-buttons {
  font-size: 1em !important;
}
.buttons-box {
  display: flex;
  flex-direction: row;
  margin: 0 0 25px 0;
  align-items: center;
  justify-content: center;
}
.pull-up {
  width: 45px !important;
  height: 35px !important;
}
.day-active {
  cursor: pointer;
  transform: scale(1.25);
  -webkit-filter: drop-shadow(5px 5px 5px #222);
  filter: drop-shadow(5px 5px 5px #222);
}

.day-group {
  padding-bottom: 8px;
}
.days-div {
  width: fit-content !important;
  position: relative;
  left: 50%;
  transform: translate(-50%, 0);
}
.appNotif {
  display: flex;
  flex: 1;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
}
.appNotif li {
  list-style: none;
  width: 300px !important;
}
.num-avatar {
  margin-left: -35px;
  margin-right: 5px;
  cursor: pointer;
  // top: 0;
}
</style>